<template>
  <v-container
    class="rounded elevation-3 bg-white h-full"
    fluid>
    <v-row>
      <v-col cols="12">
        <span class="header">SMS Logs</span>
      </v-col>
    </v-row>
    <v-row
      class="mt-5"
      no-gutters>
      <v-col cols="12">
        <broadcast-logs-header
          v-model="filter"
          @on-find="find()" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-data-table
          dense
          :headers="headers"
          :items="reports"
          hide-default-footer
          no-data-text="ไม่มีข้อมูลในช่วงเวลานี้"
          loading-text="กรุณารอสักครู่...">
          <template #[`item.createdAt`]="{ item }">
            <span>
              {{ getDateFormat(item.createdAt) }}
            </span>
          </template>
          <template #[`item.message`]="{ item }">
            <div class="text-left">
              {{ item.message }}
            </div>
          </template>
          <template #[`item.createdBy`]="{ item }">
            <span>
              {{ item.createdBy.email }}
            </span>
          </template>
        </v-data-table>
      </v-col>
      <v-col cols="12">
        <v-pagination
          v-model="page"
          :length="totalPages"
          circle
          total-visible="7" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import SmsProvider from '@/resources/SmsProvider'
import BroadcastLogsHeader from '../components/BroadcastLogsHeader.vue'

const SmsService = new SmsProvider()

export default {
  components: { BroadcastLogsHeader },
  data () {
    return {
      page: 1,
      perPage: 20,
      totalPages: 0,
      totalReports: 0,
      headers: [
        {
          text: 'Date',
          value: 'createdAt',
          sortable: true,
          align: 'center',
          width: '150px'
        },
        {
          text: 'Receiver',
          value: 'to',
          sortable: false,
          align: 'center'
        },
        {
          text: 'Message',
          value: 'message',
          sortable: false,
          align: 'center'
        },
        {
          text: 'Sender',
          value: 'createdBy',
          sortable: false,
          align: 'center'
        }
      ],
      reports: [],
      filter: {
        dates: [
          this.$dayjs().subtract(6, 'day').format('YYYY-MM-DD'),
          this.$dayjs().format('YYYY-MM-DD')
        ],
        searchInput: ''
      }
    }
  },
  watch: {
    page () {
      this.fetchReports()
    }
  },
  created () {
    this.fetchReports()
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Components/setSnackbar',
      setModal: 'Components/setModal',
      setLoading: 'Components/setLoading'
    }),
    async fetchReports (isClear = false) {
      try {
        this.setLoading({ active: true })

        if (isClear) {
          this.page = 1
        }

        const { data } = await SmsService.getSmsMessages({
          page: this.page,
          limit: this.perPage,
          startDate: this.$dayjs(this.filter.dates[0]).add(7, 'h').utc().toISOString(),
          endDate: this.$dayjs(this.filter.dates[1]).add(31, 'h').utc().toISOString(),
          search: this.filter.searchInput
        })

        this.totalPages = data.pages
        this.totalReports = data.total
        this.reports = []

        for (const result of data.results) {
          this.reports.push({
            to: result.requestData?.member?.tel || result.requestData.to,
            message: result.requestData.text,
            createdAt: result.createdAt,
            createdBy: result.createdBy
          })
        }
      } catch (error) {
        console.error('fetchReports', error)
        this.setSnackbar({
          value: true,
          message: `[GET-REPORT-ERROR]: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.setLoading({ active: false })
      }
    },
    getDateFormat (date) {
      return this.$dayjs(date).format('DD/MM/YYYY HH:mm')
    },
    find () {
      this.fetchReports(true)
    }
  }
}
</script>

<style scoped>
.w-full {
  width: 100%;
}
.h-full {
  height: 100%;
}
.header {
  font-size: 1.5rem;
  font-weight: bold;
}
.bg-white {
  background-color: white !important;
}
</style>
